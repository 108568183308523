<template>
  <v-card flat class="pa-3 mt-2">
    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col md="6" cols="12">
            <v-text-field v-model="rol.name" label="Name" dense outlined></v-text-field>
          </v-col>

          <v-col md="6" cols="12">
            <v-select
              :items="permission"
              v-model="rol.permissions"
              multiple
              dense
              label="Select role"
              item-text="name"
              item-value="name"
              chips
            ></v-select>
          </v-col>

          <v-col cols="12">
            <v-btn color="primary" class="me-3 mt-4" @click.prevent="updateRol"> Save changes </v-btn>
            <v-btn color="secondary" outlined class="mt-4" type="reset" @click.prevent="cancel"> Cancel </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from '@axios'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      rol: [],
      permission: [],
    }
  },
  computed: {
    ...mapGetters({
      hasUserRole: 'auth/hasUserRole',
      hasUserPermission: 'auth/hasUserPermission',
    }),
  },
  methods: {
    async getRol() {
      this.rol = JSON.parse(localStorage.getItem('rolEdit'))
      console.log(this.rol)
    },
    async getPermission() {
      await axios
        .get('/permission/get')
        .then(response => {
          this.permission = response.data
        })
        .catch(() => {
          // TODO: Next Update - Show notification
          console.error('Oops, Unable to get permission!')
          console.log('error :>> ', error.response)
        })
    },
    updateRol() {
      axios
        .post('/role/update', { rol: this.rol })
        .then(response => {
          console.log('updated')
          console.log(response.data)
          localStorage.removeItem('rolEdit')
          this.$alertify.success('Role updated')
          this.$router.push('/role')
        })
        .catch(error => {
          // TODO: Next Update - Show notification
          console.error('Oops, Unable to update!')
          console.log('error :>> ', error.response)
          errorMessages.value = error.response.data.error
        })
    },
    cancel() {
      this.$router.push('/role')
    },
  },
  async mounted() {
    if (this.hasUserRole('Super Admin') || this.hasUserRole('Client Owner')) {
      await this.getPermission()
      await this.getRol()
    } else {
      this.loading = false
      this.$router.push({ name: 'not-authorized' })
    }
  },
}
</script>
